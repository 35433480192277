import * as React from "react"

import Subheader from "../../components/content_blocks/special/subheader/subheader"
import Layout from "../../components/layout"
import AllLayouts from "../allLayouts"

const Service = ({ pageContext }) => {
  const {
    page: { title, contentBlocks, serviceSettings, featuredImage },
  } = pageContext

  const layouts = contentBlocks.contentBlocks || []

  // create subheader data
  const subheaderdata = {
    heading: title,
    leadingText: serviceSettings?.subheaderLeadingText,
    bodyText: serviceSettings?.subheaderText,
  }

  //match header data structure from templates/page/index.js
  const headerData = {
    bannerImage: {
      sourceUrl: featuredImage?.node?.sourceUrl,
    },
  }

  return (
    <Layout headerData={headerData}>
      <Subheader {...subheaderdata} />
      {layouts.map((layout, index) => {
        return <AllLayouts key={index} layoutData={layout} />
      })}
    </Layout>
  )
}

export default Service
